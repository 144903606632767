import Image from 'next/image';
import styled from 'styled-components';
export const ContainerWrapper = styled.div.withConfig({
  displayName: "HomeBannerstyle__ContainerWrapper",
  componentId: "sc-pqjs0u-0"
})(["", " grid-template-columns:auto auto auto;@media (max-width:", "){", " grid-template-columns:unset;}"], {
  "marginBottom": "5rem",
  "display": "grid"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "display": "block"
});
export const GridBoxDesktop = styled.div.withConfig({
  displayName: "HomeBannerstyle__GridBoxDesktop",
  componentId: "sc-pqjs0u-1"
})(["@media (max-width:", "){", "}"], ({
  theme
}) => theme.breakpoint.xl, {
  "display": "none"
});
export const Container = styled.div.withConfig({
  displayName: "HomeBannerstyle__Container",
  componentId: "sc-pqjs0u-2"
})(["", " max-width:1440px !important;"], {
  "position": "relative",
  "marginLeft": "auto",
  "marginRight": "auto",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(107 114 128 / var(--tw-bg-opacity, 1))"
});
export const ShadowLine = styled.div.withConfig({
  displayName: "HomeBannerstyle__ShadowLine",
  componentId: "sc-pqjs0u-3"
})(["", ""], {
  "position": "absolute",
  "zIndex": "10"
});
export const ShadowLineButtom = styled(ShadowLine).withConfig({
  displayName: "HomeBannerstyle__ShadowLineButtom",
  componentId: "sc-pqjs0u-4"
})(["", " background:linear-gradient(0deg,#FFF 0%,rgba(255,255,255,0.00) 100%);"], {
  "bottom": "-0.25rem",
  "height": "120px",
  "width": "100%"
});
export const ShadowLineLeft = styled(ShadowLine).withConfig({
  displayName: "HomeBannerstyle__ShadowLineLeft",
  componentId: "sc-pqjs0u-5"
})(["", " background:linear-gradient(90deg,#FFF 0%,rgba(255,255,255,0.00) 100%);@media (max-width:", "){", "}"], {
  "left": "-0.25rem",
  "height": "100%",
  "width": "120px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "display": "none"
});
export const ShadowLineRight = styled(ShadowLine).withConfig({
  displayName: "HomeBannerstyle__ShadowLineRight",
  componentId: "sc-pqjs0u-6"
})(["", " background:linear-gradient(-90deg,#FFF 0%,rgba(255,255,255,0.00) 100%);@media (max-width:", "){", "}"], {
  "right": "-0.25rem",
  "height": "100%",
  "width": "120px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "display": "none"
});
export const VideoBanner = styled.video.withConfig({
  displayName: "HomeBannerstyle__VideoBanner",
  componentId: "sc-pqjs0u-7"
})(["", " aspect-ratio:2/1;@media (max-width:", "){aspect-ratio:5/8;}"], {
  "width": "1440px",
  "objectFit": "cover"
}, ({
  theme
}) => theme.breakpoint.lg);
export const ContainerRightButton = styled.div.withConfig({
  displayName: "HomeBannerstyle__ContainerRightButton",
  componentId: "sc-pqjs0u-8"
})(["", " @media (max-width:", "){", "}"], {
  "position": "absolute",
  "right": "72px",
  "top": "2.5rem",
  "zIndex": "20",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "flex-end"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "right": "1rem",
  "top": "15px"
});
export const ButtonCoin = styled.div.withConfig({
  displayName: "HomeBannerstyle__ButtonCoin",
  componentId: "sc-pqjs0u-9"
})(["", " box-shadow:0px -3px 0px 0px #F8A323 inset,0px 1px 0px 0px #FFF8E4 inset;font-family:DB HeaventRounded;img{", "}span{", "}@media (max-width:", "){", "}"], {
  "display": "flex",
  "width": "fit-content",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem",
  "borderRadius": "100px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 212 124 / var(--tw-bg-opacity, 1))",
  "paddingLeft": "0.5rem",
  "paddingRight": "1rem"
}, {
  "aspectRatio": "1 / 1",
  "height": "2rem",
  "width": "2rem"
}, {
  "fontSize": "32px",
  "fontWeight": "900",
  "--tw-text-opacity": "1",
  "color": "rgb(217 120 55 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "gap": "0.375rem",
  "paddingLeft": "0.375rem",
  "paddingRight": "0.75rem"
});
export const WrapperSecondSideButton = styled.div.withConfig({
  displayName: "HomeBannerstyle__WrapperSecondSideButton",
  componentId: "sc-pqjs0u-10"
})(["", " a{", "}@media (max-width:", "){", "}"], {
  "marginTop": "3rem",
  "marginBottom": "3rem"
}, {
  "gap": "0.625rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "marginBottom": "2.5rem"
});
export const CampaignContainer = styled.a.withConfig({
  displayName: "HomeBannerstyle__CampaignContainer",
  componentId: "sc-pqjs0u-11"
})(["", " max-width:199px;span{", "}@media (max-width:", "){span{", "}}@media (max-width:", "){", "}"], {
  "position": "absolute",
  "bottom": "27px",
  "right": "72px",
  "zIndex": "20",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.75rem"
}, {
  "whiteSpace": "pre-wrap",
  "textAlign": "center",
  "fontSize": "21px",
  "fontWeight": "400",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "display": "none"
}, ({
  theme
}) => theme.breakpoint.md, {
  "bottom": "145px",
  "right": "1rem"
});
export const CampaignImage = styled(Image).withConfig({
  displayName: "HomeBannerstyle__CampaignImage",
  componentId: "sc-pqjs0u-12"
})(["", " @media (max-width:", "){", "}"], {
  "aspectRatio": "1 / 1",
  "height": "auto",
  "width": "160px",
  "objectFit": "contain",
  "objectPosition": "bottom"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "width": "115px"
});
export const WrapperCpTownButton = styled.div.withConfig({
  displayName: "HomeBannerstyle__WrapperCpTownButton",
  componentId: "sc-pqjs0u-13"
})(["", " @media (max-width:", "){", "}"], {
  "position": "absolute",
  "bottom": "0.5rem",
  "zIndex": "19",
  "display": "flex",
  "width": "100%",
  "justifyContent": "center"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "bottom": "2rem"
});
export const ComingSoonButton = styled.div.withConfig({
  displayName: "HomeBannerstyle__ComingSoonButton",
  componentId: "sc-pqjs0u-14"
})(["", " background:linear-gradient( 180deg,#ce0e2d 5.68%,#dd162f 60.23%,#ff2833 112.5%);box-shadow:0px 8px 12px 0px rgba(223,43,65,0.2),0px 1px 0px 0px rgba(255,255,255,0.4) inset,0px -4px 0px 0px rgba(0,0,0,0.2) inset,0px 0px 180px 0px rgba(255,40,51,0.4),0px 0px 0px 4px rgba(255,255,255,0.07);@media (max-width:", "){", "}"], {
  "borderRadius": "9999px",
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "paddingTop": "1rem",
  "paddingBottom": "1rem",
  "fontSize": "2.25rem",
  "lineHeight": "2.5rem",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "gap": "0.5rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "fontSize": "28px",
  "lineHeight": "30px"
});