import { isEmpty } from 'radash';
import { ProductResponse } from '@modules/libs/graphql/generated/graphql';
import { IProduct } from '@shared/models/product.model';
export class RecommendProductEntityMapper {
  public static toDomain(recommendProduct: ProductResponse): IProduct {
    const {
      id,
      title,
      slugUrl,
      thumbUrl,
      tag,
      weight
    } = recommendProduct;
    return {
      id,
      name: title,
      slugUrl: isEmpty(slugUrl) ? id.toString() : slugUrl,
      imageUrl: thumbUrl ?? '',
      tag,
      weight
    };
  }
}