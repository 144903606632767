import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "ProductCardstyle__Container",
  componentId: "sc-10a959i-0"
})(["", " transition:0.2s ease-in-out;.ant-image{", "}&:hover{", " box-shadow:3px 0px 15px 0px rgba(0,0,0,0.1);}@media (max-width:", "){", " &:hover{", "}}"], {
  "borderRadius": "1rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(235 235 235 / var(--tw-border-opacity, 1))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity, 1))",
  "padding": "1rem"
}, {
  "width": "100%"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "borderRadius": "0.5rem",
  "padding": "0.5rem"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(235 235 235 / var(--tw-border-opacity, 1))",
  "--tw-shadow": "0 0 #0000",
  "--tw-shadow-colored": "0 0 #0000",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
});
export const ImgProduct = styled(Image).withConfig({
  displayName: "ProductCardstyle__ImgProduct",
  componentId: "sc-10a959i-1"
})(["", ""], {
  "aspectRatio": "1 / 1",
  "width": "100%",
  "borderRadius": "0.5rem",
  "objectFit": "contain"
});
export const ProductTitle = styled.div.withConfig({
  displayName: "ProductCardstyle__ProductTitle",
  componentId: "sc-10a959i-2"
})(["", " height:", ";display:-webkit-box;line-clamp:", ";-webkit-line-clamp:", ";-webkit-box-orient:vertical;@media (max-width:", "){", " height:", ";}"], {
  "marginTop": "1rem",
  "overflow": "hidden",
  "fontSize": "21px",
  "fontWeight": "500",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity, 1))"
}, ({
  $maxLineName
}) => `${($maxLineName ?? 2) * 22}px`, ({
  $maxLineName
}) => `${$maxLineName ?? 2}`, ({
  $maxLineName
}) => `${$maxLineName ?? 2}`, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "0.5rem",
  "fontSize": "1rem",
  "lineHeight": "18px"
}, ({
  $maxLineName
}) => `${($maxLineName ?? 2) * 18}px`);
export const ProductWeight = styled.div.withConfig({
  displayName: "ProductCardstyle__ProductWeight",
  componentId: "sc-10a959i-3"
})(["", " @media ( max-width:", "){", "}"], {
  "fontSize": "22px",
  "fontWeight": "400",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(167 167 167 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "1rem",
  "lineHeight": "18px"
});
export const ProductCardAction = styled.div.withConfig({
  displayName: "ProductCardstyle__ProductCardAction",
  componentId: "sc-10a959i-4"
})(["", ""], {
  "marginTop": "0.75rem",
  "display": "flex",
  "justifyContent": "space-between"
});
export const ListTag = styled.div.withConfig({
  displayName: "ProductCardstyle__ListTag",
  componentId: "sc-10a959i-5"
})(["", ""], {
  "display": "flex",
  "flexWrap": "wrap",
  "gap": "0.25rem"
});
export const HeartAction = styled.div.withConfig({
  displayName: "ProductCardstyle__HeartAction",
  componentId: "sc-10a959i-6"
})(["", " span{", "}@media (max-width:", "){", "}"], {
  "display": "flex",
  "alignItems": "center",
  "gap": "0.125rem"
}, {
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgb(167 167 167 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "display": "none"
});