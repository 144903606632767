import { FC } from 'react';
import Image from 'next/image';
import { analytics } from '@shared/utils/segment.utils';
import { Container, TextDescription, Title, WrapperLeft, WrapperRight } from './FoodService.style';
const FoodService: FC = () => {
  const onClickFoodService = (title: string) => {
    analytics.track('Food Service Click', {
      event: 'food_service_click',
      button_name: title
    });
  };
  return <Container>
            <WrapperLeft href="/food-service" onClick={() => onClickFoodService('ฟู้ดเซอร์วิส')}>
                <Title>ฟู้ดเซอร์วิส</Title>
                <TextDescription>
                    <span>สดใหม่อยู่เสมอ</span>
                    <Image src="/icons/arrow-right-white.svg" alt="arrow-right" width={22} height={22} />
                </TextDescription>
            </WrapperLeft>
            <WrapperRight href="/recipe" onClick={() => onClickFoodService('กินอะไรดี?')}>
                <Title>กินอะไรดี?</Title>
                <TextDescription>
                    <span>นึกเมนูไม่ออก บอกซีพี</span>
                    <Image src="/icons/arrow-right-white.svg" alt="arrow-right" width={22} height={22} />
                </TextDescription>
            </WrapperRight>
        </Container>;
};
export default FoodService;