import { FC, Key } from 'react';
import { ProductCard } from '@components/product/card';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import { IProduct } from '@shared/models/product.model';
import { analytics } from '@shared/utils/segment.utils';
import { Container, ProductGridWrapper } from './RecommendProduct.style';
interface Props {
  data: IProduct[];
}
const RecommendProduct: FC<Props> = ({
  data
}: Props) => {
  const handleProductViewedTracker = (productId: Key, productName: string, productCate: string[]) => {
    analytics.track('Product Viewed', {
      product_id: productId,
      product_name: productName,
      product_category: productCate
    });
  };
  return <Container>
            <Title text={'ผลิตภัณฑ์ซีพีแนะนำ'} extra={<SeeAll url="/product" onClick={() => {
      analytics.track('Product See All Click', {
        event: 'product_see_all_click'
      });
    }} />} />
            <ProductGridWrapper>
                {data.map((product, index) => <div key={product.id} onClick={() => {
        analytics.track('Recommended Product Click', {
          event: 'recommended_product_click',
          position: index + 1
        });
      }}>
                        <ProductCard imageUrl={product.imageUrl} title={product.name} weight={product.weight} tag={product.tag} slug={product.slugUrl} onClickTracker={() => {
          handleProductViewedTracker(product.id, product.name, product.tag);
        }} />
                    </div>)}
            </ProductGridWrapper>
        </Container>;
};
export default RecommendProduct;