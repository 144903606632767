import { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { isEmpty } from 'radash';
import { useLockedBody } from 'usehooks-ts';
import { bannerStore } from '@modules/banner/adapter/outbound/stores/banner.store';
import { newCollectionStore } from '@modules/collections/adapter/outbound/stores/newCollection.store';
import { cpConnectListStore } from '@modules/cpConnect/adapter/outbound/stores/cpConnect.store';
import { homeCampaignStore } from '@modules/home/adapter/outbound/stores/campaign.store';
import { gameBannerStore } from '@modules/home/adapter/outbound/stores/gameBanner.store';
import { homePopupStore } from '@modules/home/adapter/outbound/stores/popup.store';
import { recommendProductStore } from '@modules/home/adapter/outbound/stores/recommendProduct.store';
import { trendsForYouStore } from '@modules/home/adapter/outbound/stores/trendsForYou.store';
import { recommendTipsTricksStore } from '@modules/tipsAndTricks/adapter/outbound/stores/recommendTipsTricks.store';
import { tiktokHashtagStore } from '@modules/trends/adapter/outbound/stores/tiktokHashtag.store';
import { tiktokVdoByHashtagStore } from '@modules/trends/adapter/outbound/stores/tiktokVdoByHashtag.store';
import { HomeCpConnect } from '@components/cpConnect';
import HomeBanner from '@components/home/banner/HomeBanner';
import FoodService from '@components/home/foodService/FoodService';
import RecommendProduct from '@components/home/recommendProduct/RecommendProduct';
import TipsAndTricks from '@components/home/tipsAndtricks/TipsAndTricks';
import { TrendHotTikTok } from '@components/home/trendHotTikTok';
import HomePopup from '@components/popup/HomePopup';
import { MonthlyCollection } from '@components/recipe/main/collection';
import RegisterBannerLogic from '@components/registerBanner/RegisterBanner.logic';
import GameBannerSkeleton from '@components/skeleton/banner/gameBanner/GameBanner';
import { ITab } from '@shared/models/tab.model';
const BannerSlide = dynamic(() => import('@components/bannerSlide/BannerSlide'));
const HomeProvider: FC = () => {
  const router = useRouter();
  const [_locked, setLocked] = useLockedBody(false, 'root');
  const [tiktokHashtagTab, setTiktokHashtagTab] = useState<ITab[]>([]);
  const [activeHashtag, setActiveHashtag] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const {
    gameBanner,
    fetchGameBanner
  } = gameBannerStore(state => state);
  const {
    homeCampaign,
    fetchHomeCampaign
  } = homeCampaignStore(state => state);
  const {
    homePopup,
    fetchHomePopup
  } = homePopupStore(state => state);
  const {
    tiktokHashtag,
    fetchTikTokHashtag
  } = tiktokHashtagStore(state => state);
  const {
    tiktokVdoByHashtag,
    fetchTikTokVdoByHashtag
  } = tiktokVdoByHashtagStore(state => state);
  const {
    newCollectionList,
    setNewCollection
  } = newCollectionStore(state => state);
  const {
    recommendProduct,
    fetchRecommendProduct
  } = recommendProductStore(state => state);
  const {
    trends,
    fetchTrends,
    isOpenTrendForyou,
    setIsOpenTrendForyou,
    activeTrends,
    setActiveTrends,
    resetTrendsPopoverState
  } = trendsForYouStore(state => state);
  const {
    bannerList,
    setBannerList
  } = bannerStore(state => state);
  const {
    recommendTipsTricks,
    fetchRecommendTipsTricks
  } = recommendTipsTricksStore(state => state);
  const {
    homeCpConnectList,
    fetchCpConnectList
  } = cpConnectListStore(state => state);
  const activeTiktokVdoByHashtag = tiktokVdoByHashtag[activeHashtag] ?? [];
  useEffect(() => {
    fetchHomePopup();
    fetchGameBanner('home');
    fetchTikTokHashtag();
    fetchTrends();
    fetchHomeCampaign();
    fetchRecommendProduct();
    fetchRecommendTipsTricks();
    if (newCollectionList.length === 0) setNewCollection();
    if (homeCpConnectList.length === 0) fetchCpConnectList('home', 4);
  }, []);
  useEffect(() => {
    if (!isEmpty(router.pathname)) setBannerList(router.pathname);
  }, [router.pathname]);
  useEffect(() => {
    setShouldShowSkeleton(isEmpty(gameBanner.id));
  }, [gameBanner]);
  useEffect(() => {
    homePopup && !shouldShowSkeleton && setIsPopupOpen(true);
  }, [homePopup, shouldShowSkeleton]);
  useEffect(() => {
    setLocked(isPopupOpen || shouldShowSkeleton);
  }, [isPopupOpen, shouldShowSkeleton]);
  useEffect(() => {
    tiktokHashtag.length > 0 && tiktokHashtagMapperToTag();
  }, [tiktokHashtag]);
  const tiktokHashtagMapperToTag = () => {
    const output = tiktokHashtag.map(({
      hashtag
    }) => {
      return {
        key: hashtag,
        label: hashtag
      };
    });
    setTiktokHashtagTab(output);
  };
  const onTiktokHashtagTabChange = () => {
    if (activeHashtag !== '' && activeTiktokVdoByHashtag.length === 0) {
      fetchTikTokVdoByHashtag(activeHashtag);
    }
  };
  return <>
            <HomePopup popupItem={homePopup} isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
            {shouldShowSkeleton ? <GameBannerSkeleton /> : <>
                    <HomeBanner gameBanner={gameBanner} homeCampaign={homeCampaign} trendsForYou={trends} isOpenTrendForyou={isOpenTrendForyou} setIsOpenTrendForyou={setIsOpenTrendForyou} activeTrends={activeTrends} setActiveTrends={setActiveTrends} onClosePopover={resetTrendsPopoverState} />
                    <TrendHotTikTok onTabChange={onTiktokHashtagTabChange} tiktokHashtagList={tiktokHashtagTab} data={activeTiktokVdoByHashtag} activeKey={activeHashtag} setActiveKey={setActiveHashtag} />
                    <MonthlyCollection data={newCollectionList} />
                    {!isEmpty(bannerList.bottom) && <BannerSlide list={bannerList.bottom} />}
                    <RecommendProduct data={recommendProduct} />
                    <TipsAndTricks data={recommendTipsTricks} />
                    <RegisterBannerLogic />
                    {homeCpConnectList.length > 0 && <HomeCpConnect itemList={homeCpConnectList} />}
                    <FoodService />
                </>}
        </>;
};
export default HomeProvider;