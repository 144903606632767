import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.a.withConfig({
  displayName: "CpConnectCardstyle__Container",
  componentId: "sc-ywq8ib-0"
})(["", " transition:all .2s ease-out;&:hover{transition:all 0.3s ease-in;box-shadow:0px 0px 0px 0px rgba(0,0,0,0.05),0px 7px 16px 0px rgba(0,0,0,0.05),0px 29px 29px 0px rgba(0,0,0,0.04),0px 66px 40px 0px rgba(0,0,0,0.03),0px 117px 47px 0px rgba(0,0,0,0.01),0px 183px 51px 0px rgba(0,0,0,0);}@media (max-width:", "){&:hover{box-shadow:none;}}"], {
  "overflow": "hidden",
  "borderRadius": "1rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(235 235 235 / var(--tw-border-opacity, 1))",
  "paddingBottom": "1rem"
}, ({
  theme
}) => theme.breakpoint.md);
export const Cover = styled.div.withConfig({
  displayName: "CpConnectCardstyle__Cover",
  componentId: "sc-ywq8ib-1"
})(["img{", " aspect-ratio:13/6;}"], {
  "width": "100%",
  "objectFit": "cover"
});
export const LogoContainer = styled.div.withConfig({
  displayName: "CpConnectCardstyle__LogoContainer",
  componentId: "sc-ywq8ib-2"
})(["", ""], {
  "marginTop": "-45px",
  "marginBottom": "0.5rem",
  "display": "flex",
  "justifyContent": "center"
});
export const LogoWrapper = styled.div.withConfig({
  displayName: "CpConnectCardstyle__LogoWrapper",
  componentId: "sc-ywq8ib-3"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "aspectRatio": "1 / 1",
  "width": "5rem",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(235 235 235 / var(--tw-border-opacity, 1))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "72px"
});
export const Logo = styled(Image).withConfig({
  displayName: "CpConnectCardstyle__Logo",
  componentId: "sc-ywq8ib-4"
})(["", ""], {
  "aspectRatio": "1 / 1",
  "width": "100%",
  "borderRadius": "9999px",
  "borderWidth": "2px",
  "borderStyle": "solid",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 255 255 / var(--tw-border-opacity, 1))",
  "objectFit": "contain"
});
export const WrapperContent = styled.div.withConfig({
  displayName: "CpConnectCardstyle__WrapperContent",
  componentId: "sc-ywq8ib-5"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
export const Title = styled.div.withConfig({
  displayName: "CpConnectCardstyle__Title",
  componentId: "sc-ywq8ib-6"
})(["", " @media (max-width:", "){", "}"], {
  "textAlign": "center",
  "fontSize": "28px",
  "fontWeight": "700",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "minHeight": "48px",
  "fontSize": "1.5rem",
  "lineHeight": "1.5rem"
});
export const Description = styled.div.withConfig({
  displayName: "CpConnectCardstyle__Description",
  componentId: "sc-ywq8ib-7"
})(["", " display:-webkit-box;line-clamp:2;-webkit-line-clamp:2;-webkit-box-orient:vertical;@media (max-width:", "){", "}"], {
  "height": "44px",
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "textAlign": "center",
  "fontSize": "21px",
  "fontWeight": "400",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgb(167 167 167 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "32px",
  "fontSize": "1rem",
  "lineHeight": "18px"
});