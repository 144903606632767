import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetHomePopupUsecase } from '@modules/home/application/usecases/popup.usecase';
import { IHomePopup } from '@modules/home/domain/models/popup.model';
import { HomePopupRepositoryImpl } from '../../inbound/repositories/popup.repository';
interface HomePopupState {
  homePopup: IHomePopup;
  fetchHomePopup: () => void;
}
export const homePopupStore = create<HomePopupState>(set => ({
  homePopup: null,
  fetchHomePopup: async (): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const homePopupRepoImpl = new HomePopupRepositoryImpl();
      const getHomePopupUsecase = new GetHomePopupUsecase(homePopupRepoImpl);
      const result = await getHomePopupUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          homePopup: result.value
        }));
      }
    } catch (err) {
      console.error('Error fetchHomePopup ', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));