import { motion } from 'framer-motion';
import styled from 'styled-components';
export const Container = styled(motion.div).withConfig({
  displayName: "CpTownButtonstyle__Container",
  componentId: "sc-1cohqwd-0"
})(["", " box-shadow:0px 8px 12px 0px rgba(223,43,65,0.2),0px 1px 0px 0px rgba(255,255,255,0.4) inset,0px -4px 0px 0px rgba(0,0,0,0.2) inset,0px 0px 180px 0px rgba(255,40,51,0.4),0px 0px 0px 4px rgba(255,255,255,0.07);&:active{transform:translateY(1px);box-shadow:0px 8px 12px 0px rgba(223,43,65,0.2),0px 1px 0px 0px rgba(255,255,255,0.4) inset,0px -3px 0px 0px rgba(0,0,0,0.2) inset,0px 0px 180px 0px rgba(255,40,51,0.4),0px 0px 0px 4px rgba(255,255,255,0.07);}"], {
  "borderRadius": "100px"
});
export const WrapperLinkout = styled.a.withConfig({
  displayName: "CpTownButtonstyle__WrapperLinkout",
  componentId: "sc-1cohqwd-1"
})(["", " &:hover{", "}@media (max-width:", "){", "}"], {
  "display": "inline-flex",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.75rem",
  "borderRadius": "100px",
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "paddingTop": "1rem",
  "paddingBottom": "1rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "gap": "0.5rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
export const Title = styled(motion.h1).withConfig({
  displayName: "CpTownButtonstyle__Title",
  componentId: "sc-1cohqwd-2"
})(["", " @media (max-width:", "){", "}"], {
  "position": "relative",
  "left": "0px",
  "fontSize": "2.25rem",
  "lineHeight": "2.5rem",
  "fontWeight": "700"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "fontSize": "28px",
  "lineHeight": "30px"
});
export const WrapperIcon = styled(motion.div).withConfig({
  displayName: "CpTownButtonstyle__WrapperIcon",
  componentId: "sc-1cohqwd-3"
})(["", " img{", "}"], {
  "position": "relative",
  "right": "0px"
}, {
  "aspectRatio": "1 / 1",
  "width": "2rem"
});